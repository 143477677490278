import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";

import Raphael from 'raphael/raphael'
global.Raphael = Raphael

import { DonutChart } from 'vue-morris'
import { BarChart } from 'vue-morris'

window.$ = window.jQuery = require("jquery");

export default {
    name: "ReportePage",
    data() {
        let ss = new MainService();
        return {
            msg: "EstadisticaPage",
            ss: ss,
           
            reportes: [],
            reporte: {},

            filtro: {},

            UnidadAcademicaElegida: {},

            carreras:{},
            carrera: [],

            unidadAcademicas: {},
            unidadAcademica: [],

            array: [],

            donutData: [
                { label: 'Red', value: 300 },
                { label: 'Blue', value: 50 },
                { label: 'Yellow', value: 100 }
              ],

              /* data: [
                { y: '2006', a: 100, b: 90 },
                { y: '2007', a: 75,  b: 65 },
                { y: '2008', a: 50,  b: 40 },
                { y: '2009', a: 75,  b: 65 },
                { y: '2010', a: 50,  b: 40 },
                { y: '2011', a: 75,  b: 65 },
                { y: '2012', a: 100, b: 90 }
            ],  */
               
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            options: [],
            isLoading: false,
            errorBag: {},
        };

        
    },

    methods: {

        getEstadisticas(){
            this.ss.statDocumentoCarrera().then(
                (result) => {
                    
                    let array = [];
                    let response = result.data;

                    response.forEach(
                        element => array.push(element)       
                    );
                    
                    this.array = array;
                }
            )
        },

        getCarrera() {
            this.ss.listCarrera().then(
                (result) => {
                    let response = result.data;
                    this.carreras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        
        
    },
    components: {
        dataTable,
        Loader,
        DonutChart,
        BarChart
    
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else{
            this.getCarrera();
            this.getUnidadAcademica();
            this.getEstadisticas();
        }
    }
};
