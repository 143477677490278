import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'

import MenuExterno from "@/components/MenuExterno";
import FooterExterno from "@/components/FooterExterno";

Vue.use(VueAxios, axios)

Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI,
		rutaApp:process.env.VUE_APP_MAIN_SERVICE

	}
});


export default {
	name: 'RepositorioPage',
	data() {
		let ss = new MainService();
		return {
			ss: ss,

			ajax: {
                "url": ss.indexDocumento(),
                headers: ss.getToken(),
            },

			documentos: [],
            documento: {},

			q:"",


			ti:false,
			ca:false,
			re:false,
			unia:false,
			ai:false,

			filtros:{
				titulo:"",
				responsable:"",
				carrera:"",
				uniaca:"",
				areainv:"",
			},
			loginRequest: {},
			errorBag: {},
			slide: 0,
			sliding: null,
			rutaApp:process.env.VUE_APP_MAIN_SERVICE,
			UAescogida:0,
			UAsagaEscogida:0,

			MostrarFiltrosTipoPost:0,
			
			auth:{},
			datosInfo:{},
			nroDocumentos:4,
			filtroCarrera:false,
			
			carreras:[],
			unidadacademicas:[],
			areaInvestigaciones:[]
		};

	},
    components: {
		MenuExterno,
		FooterExterno,
    },
	props: {
		msg: String
	},
	methods: {


		
		
		queryTermino(){
			this.ss.select2(this.qp).then((res) => {
                console.log(res);
                if(res.data.success){
                this.documentos = res.data.items;
					console.log(this.documentos);
                }else{
                    this.documentos = [];
                }
              })
		},

		
		queryVacio(){
			this.getDocumento();
			this.q = "";
		},

		
		filtrarDocTitulo() {
			
			this.filtros.carrera="";
			this.filtros.responsable="";
			this.filtros.uniaca="";
			this.filtros.areainv="";
			
			console.log(this.filtros);

            this.ss.filtrarCarreraDocumento(this.filtros).then(
                (result) => {
                    let response = result.data;
                    this.documentos = response.data;
                    this.documentos = this.documentos.slice(0,this.nroDocumentos);

                }
            ).catch(error => {
                console.log(error);
            });
        },

		filtrarDocCarrera() {
			
			this.filtros.titulo="";
			this.filtros.responsable="";
			this.filtros.uniaca="";
			this.filtros.areainv="";
			
			console.log(this.filtros);

            this.ss.filtrarCarreraDocumento(this.filtros).then(
                (result) => {
                    let response = result.data;
                    this.documentos = response.data;
                    this.documentos = this.documentos.slice(0,this.nroDocumentos);

                }
            ).catch(error => {
                console.log(error);
            });
        },

		filtrarGeneral() {
			
		
			console.log(this.filtros);
			
            this.ss.filtrarCarreraDocumento(this.filtros).then(
                (result) => {
                    let response = result.data;
                    this.documentos = response.data;
                    this.documentos = this.documentos.slice(0,this.nroDocumentos);

                }
            ).catch(error => {
                console.log(error);
            });
        },

		
		getUA() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

		getCarrera() {
            this.ss.listCarrera().then(
                (result) => {
                    let response = result.data;
                    this.carreras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },


		getAI() {
            this.ss.listAreaInvestigacion().then(
                (result) => {
                    let response = result.data;
                    this.areaInvestigaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

		getDocumento() {
            this.ss.listDocumento().then(
                (result) => {
                    let response = result.data;
                    this.documentos = response.data;
                    this.documentos = this.documentos.slice(0,this.nroDocumentos);
                }
            ).catch(error => {
                console.log(error);
            });
        },

		
		verMas(){
			if(this.filtroCarrera){
				this.nroDocumentos=this.nroDocumentos+2;
				this.filtrarDocCarrera();
			}
			else if(!this.filtroCarrera){
				this.nroDocumentos=this.nroDocumentos+2;
				this.getDocumento();
			} 
			
		},

		verMenos(){
			if(this.filtroCarrera){
				this.nroDocumentos=this.nroDocumentos-2;
				this.filtrarDocCarrera();
			}
			else if(!this.filtroCarrera){
				this.nroDocumentos=this.nroDocumentos-2;
				this.getDocumento();
			} 
			
		},

		quitarFiltro(){
			// this.ca="";
			// this.qp ="";
			this.filtros={};
			this.nroDocumentos=4,
			this.filtroCarrera=false;
			this.getDocumento();
		},

		irDocumentoList(id){
			console.log("ver mas"+id);
			this.$router.push('/Documento'+id) 
			
			//router.go('/VerificarDare');
		},

		
		getProgramasPorUA(id,idSaga) {
			this.MostrarFiltrosTipoPro=1;
			this.UAescogida=id;
			this.UAsagaEscogida=idSaga;
			let params = {
				id: id,
				idSaga:idSaga,
			}

			console.log(params);
            this.ss.listVistaPostgradoporUA(params).then(
                (result) => {
                    let response = result.data;
                    this.programasPost = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
		},

		fitroSegunTipo(idTipo){
			console.log(idTipo);
			console.log(this.UAescogida);

			let params = {
				id: this.UAescogida,
				idSaga:this.UAsagaEscogida,
				tipoProg:idTipo,
			}
			console.log(params);
			this.ss.listVistaPostgradoporUAyTipo(params).then(
                (result) => {
                    let response = result.data;
                    this.programasPost = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
		},

		
		cerrarInfo(){
			this.$refs['info'].hide();
		},

		modalLogin(){
			this.$refs['info'].hide();
			this.$root.$refs.MenuExterno.abrirModalLogin();
			// this.$forceUpdate();
		},

		modalRegistro(){
			this.$refs['info'].hide();
			this.$root.$refs.MenuExterno.abrirModaReg();
			// this.$forceUpdate();
		},
		
		onSlideStart() {
			this.sliding = true
		},
		onSlideEnd() {
			this.sliding = false
		},

		enviarInfo(){
			console.log("datos persona informacion");
			console.log(this.datosInfo);
			this.datosInfo={};
		},

		showDocumento(id) {
            console.log(id);
            this.isLoading=true;
            this.ss.showDocumento(id).then(
                (result) => {
                    let response = result.data;
                    this.documento = response.data;
                    // this.$refs['view-documento'].show();
					this.$refs['modalDocu'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
		

		
	},
	mounted() {
		this.auth = JSON.parse(localStorage.getItem("persona"));
		window.scrollTo(0, 0);
		//this.getProgramasPost();
		this.getCarrera();
		this.getDocumento();
		this.getUA();
		this.getAI();


		// if (!this.auth) {
        //     this.$refs['info'].show();
        // } 
		
		
	}
}